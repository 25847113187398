import styled, { css } from "styled-components";
import { darken, lighten } from "polished";

import colors from "../colors";

const sharedStyles = css`
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: inherit;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.white};
  background-color: ${colors.secondary};
  text-align: center;
  letter-spacing: 0.5px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  &:hover {
    background-color: ${lighten(0.1, colors.secondary)};
  }
  &:active {
    background-color: ${darken(0.1, colors.secondary)};
  }
`;

const Button = styled.button`
  ${sharedStyles};
  &:focus {
    outline: 0;
  }
`;

const Anchor = styled.a`
  ${sharedStyles};
  user-select: "none";
`;

export { Button, Anchor };
