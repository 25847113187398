import React from "react";
import { render } from "react-dom";
import { injectGlobal } from "styled-components";

import Home from "./Home";

injectGlobal`
  html, #root {
    height: 100%;
  }
  body {
    height: 100%;
    font-family: "Roboto Slab", sans-serif;
    margin: 0;
  }
`;

render(<Home />, document.getElementById("root"));
