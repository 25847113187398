import React, { Component } from "react";
import styled from "styled-components";
import Particles from "react-particles-js";

import { Anchor } from "./components/Buttons";

const StyledParticles = styled(Particles).attrs({
  params: {
    particles: {
      number: {
        value: 50
      },
      color: {
        value: "#000"
      },
      shape: {
        type: "circle"
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#000000",
        opacity: 0.4,
        width: 1
      },
      size: {
        value: 3
      }
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        }
      }
    }
  }
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -10;
`;

const StyledAnchor = styled(Anchor)`
  align-self: flex-start;
  margin-top: 10px;
`;

const Container = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5%;
`;

const Title = styled.span`
  font: 72px "Lato", sans-serif;
`;

const Description = styled.span`
  font-weight: 300;
  font-size: 32px;
`;

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Title>
            Hi! I'm <b>Felix</b>
          </Title>
          <Description>
            I'm a final year Computer Science student at NTU
          </Description>
          <StyledAnchor
            target="_blank"
            href="https://storage.googleapis.com/felixputera/20180913.pdf"
          >
            My Resume
          </StyledAnchor>
        </Container>
        <StyledParticles />
      </React.Fragment>
    );
  }
}
